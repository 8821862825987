import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The chameleon design system consists of a few variables to define the brands
identity, we call them
`}<a parentName="p" {...{
        "href": "https://chameleon.mediahuis.be/foundations"
      }}>{`foundations`}</a>{`. To getting started we
will need a few variables and assest. We'l sum up the minimum required, to
onboard to the design system. If you need more information, you can always
contact us via `}<a parentName="p" {...{
        "href": "mailto:designsystem@mediahuis.be"
      }}>{`designsystem@mediahuis.be`}</a>{`.`}</p>
    <h1 {...{
      "id": "minimum-required-foundations",
      "style": {
        "position": "relative"
      }
    }}>{`Minimum required foundations`}</h1>
    <h2 {...{
      "id": "colors",
      "style": {
        "position": "relative"
      }
    }}>{`Colors`}</h2>
    <p><a parentName="p" {...{
        "href": "https://chameleon.mediahuis.be/foundations/color"
      }}>{`https://chameleon.mediahuis.be/foundations/color`}</a></p>
    <h3 {...{
      "id": "primary",
      "style": {
        "position": "relative"
      }
    }}>{`Primary`}</h3>
    <p>{`10 shades + base, light and dark in HEX-format (these values should come from
the shades)`}</p>
    <h3 {...{
      "id": "secondary",
      "style": {
        "position": "relative"
      }
    }}>{`Secondary`}</h3>
    <p>{`10 shades + base, light and dark in HEX-format (these values should come from
the shades)`}</p>
    <h3 {...{
      "id": "tertiary",
      "style": {
        "position": "relative"
      }
    }}>{`Tertiary`}</h3>
    <p>{`10 shades + base, light and dark in HEX-format (these values should come from
the shades)`}</p>
    <h3 {...{
      "id": "marketing",
      "style": {
        "position": "relative"
      }
    }}>{`Marketing`}</h3>
    <p>{`10 shades + base, light and dark in HEX-format (these values should come from
the shades)`}</p>
    <h2 {...{
      "id": "fonts",
      "style": {
        "position": "relative"
      }
    }}>{`Fonts`}</h2>
    <p><a parentName="p" {...{
        "href": "https://chameleon.mediahuis.be/foundations/fonts"
      }}>{`https://chameleon.mediahuis.be/foundations/fonts`}</a></p>
    <h3 {...{
      "id": "primary-1",
      "style": {
        "position": "relative"
      }
    }}>{`Primary`}</h3>
    <h4 {...{
      "id": "regular",
      "style": {
        "position": "relative"
      }
    }}>{`Regular`}</h4>
    <ul>
      <li parentName="ul">{`.ttf`}</li>
      <li parentName="ul">{`.woff`}</li>
      <li parentName="ul">{`.woff2`}</li>
    </ul>
    <h4 {...{
      "id": "medium",
      "style": {
        "position": "relative"
      }
    }}>{`Medium`}</h4>
    <ul>
      <li parentName="ul">{`.ttf`}</li>
      <li parentName="ul">{`.woff`}</li>
      <li parentName="ul">{`.woff2`}</li>
    </ul>
    <h4 {...{
      "id": "bold",
      "style": {
        "position": "relative"
      }
    }}>{`Bold`}</h4>
    <ul>
      <li parentName="ul">{`.ttf`}</li>
      <li parentName="ul">{`.woff`}</li>
      <li parentName="ul">{`.woff2`}</li>
    </ul>
    <h3 {...{
      "id": "secondary-1",
      "style": {
        "position": "relative"
      }
    }}>{`Secondary`}</h3>
    <h4 {...{
      "id": "regular-1",
      "style": {
        "position": "relative"
      }
    }}>{`Regular`}</h4>
    <ul>
      <li parentName="ul">{`.ttf`}</li>
      <li parentName="ul">{`.woff`}</li>
      <li parentName="ul">{`.woff2`}</li>
    </ul>
    <h4 {...{
      "id": "medium-1",
      "style": {
        "position": "relative"
      }
    }}>{`Medium`}</h4>
    <ul>
      <li parentName="ul">{`.ttf`}</li>
      <li parentName="ul">{`.woff`}</li>
      <li parentName="ul">{`.woff2`}</li>
    </ul>
    <h4 {...{
      "id": "bold-1",
      "style": {
        "position": "relative"
      }
    }}>{`Bold`}</h4>
    <ul>
      <li parentName="ul">{`.ttf`}</li>
      <li parentName="ul">{`.woff`}</li>
      <li parentName="ul">{`.woff2`}</li>
    </ul>
    <h2 {...{
      "id": "brand-logos",
      "style": {
        "position": "relative"
      }
    }}>{`Brand logos`}</h2>
    <p><a parentName="p" {...{
        "href": "https://chameleon.mediahuis.be/foundations/logos"
      }}>{`https://chameleon.mediahuis.be/foundations/logos`}</a></p>
    <ul>
      <li parentName="ul">{`brand-main (svg)`}</li>
      <li parentName="ul">{`brand-main-square (svg)`}</li>
      <li parentName="ul">{`brand-main-plus (svg)`}</li>
    </ul>
    <h2 {...{
      "id": "icons",
      "style": {
        "position": "relative"
      }
    }}>{`Icons`}</h2>
    <p><a parentName="p" {...{
        "href": "https://chameleon.mediahuis.be/foundations/iconography"
      }}>{`https://chameleon.mediahuis.be/foundations/iconography`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://chameleon.mediahuis.be/components/icon/design"
      }}>{`https://chameleon.mediahuis.be/components/icon/design`}</a></p>
    <p>{`At least brand & brand-fill (no color, bounding box) Other icons can be filled
in, but are not required to start.`}</p>
    <h2 {...{
      "id": "other-foundations",
      "style": {
        "position": "relative"
      }
    }}>{`Other foundations`}</h2>
    <p>{`There are other foundations in chameleon, these are filled in with sensisble
defaults. If you want to modify them, please let us know.
`}<a parentName="p" {...{
        "href": "https://chameleon.mediahuis.be/foundations"
      }}>{`https://chameleon.mediahuis.be/foundations`}</a></p>
    <h2 {...{
      "id": "button--iconbutton",
      "style": {
        "position": "relative"
      }
    }}>{`Button & IconButton`}</h2>
    <p><a parentName="p" {...{
        "href": "https://chameleon.mediahuis.be/components/button"
      }}>{`https://chameleon.mediahuis.be/components/button`}</a></p>
    <h3 {...{
      "id": "primary-button",
      "style": {
        "position": "relative"
      }
    }}>{`Primary Button`}</h3>
    <p>{`(values from the foundations)`}</p>
    <ul>
      <li parentName="ul">{`background`}</li>
      <li parentName="ul">{`color`}</li>
      <li parentName="ul">{`hover background`}</li>
      <li parentName="ul">{`hover color`}</li>
      <li parentName="ul">{`active background`}</li>
      <li parentName="ul">{`active color`}</li>
      <li parentName="ul">{`border-radius`}</li>
    </ul>
    <h3 {...{
      "id": "secondary-button",
      "style": {
        "position": "relative"
      }
    }}>{`Secondary Button`}</h3>
    <p>{`(values from the foundations)`}</p>
    <ul>
      <li parentName="ul">{`background`}</li>
      <li parentName="ul">{`color`}</li>
      <li parentName="ul">{`hover background`}</li>
      <li parentName="ul">{`hover color`}</li>
      <li parentName="ul">{`active background`}</li>
      <li parentName="ul">{`active color`}</li>
      <li parentName="ul">{`border-radius`}</li>
    </ul>
    <h3 {...{
      "id": "outline",
      "style": {
        "position": "relative"
      }
    }}>{`Outline`}</h3>
    <p>{`(values from the foundations)`}</p>
    <ul>
      <li parentName="ul">{`background`}</li>
      <li parentName="ul">{`color`}</li>
      <li parentName="ul">{`hover background`}</li>
      <li parentName="ul">{`hover color`}</li>
      <li parentName="ul">{`active background`}</li>
      <li parentName="ul">{`active color`}</li>
      <li parentName="ul">{`border-radius`}</li>
    </ul>
    <h3 {...{
      "id": "plain",
      "style": {
        "position": "relative"
      }
    }}>{`Plain`}</h3>
    <p>{`(values from the foundations)`}</p>
    <ul>
      <li parentName="ul">{`background`}</li>
      <li parentName="ul">{`color`}</li>
      <li parentName="ul">{`hover background`}</li>
      <li parentName="ul">{`hover color`}</li>
      <li parentName="ul">{`active background`}</li>
      <li parentName="ul">{`active color`}</li>
      <li parentName="ul">{`border-radius`}</li>
    </ul>
    <h2 {...{
      "id": "branded-heading",
      "style": {
        "position": "relative"
      }
    }}>{`Branded heading`}</h2>
    <p><a parentName="p" {...{
        "href": "https://chameleon.mediahuis.be/components/brandedheading/code"
      }}>{`https://chameleon.mediahuis.be/components/brandedheading/code`}</a></p>
    <p>{`Will be auto generated and look like the white label branded-heading. This
component is fully customizable if you want. Let us know how you would like it
to look like.`}</p>
    <h2 {...{
      "id": "other-components",
      "style": {
        "position": "relative"
      }
    }}>{`Other components`}</h2>
    <p><a parentName="p" {...{
        "href": "https://chameleon.mediahuis.be/components"
      }}>{`https://chameleon.mediahuis.be/components`}</a></p>
    <p>{`Other components are automatic generated. And can still be modified. We suggest
to first generate them, and modify what's needed afterwards.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      